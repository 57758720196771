<template>
  <el-card
    v-loading="loading"
    class="payment-summary"
  >
    <h2 class="payment-title">
      Confirmar recebimento de
    </h2>
    <payment-selector-list
      :data="details.payment"
      :hide-total="true"
    />
    <h2 class="discount-info">Valor: {{ formatMoney(details.amount) }}</h2>
    <h2 class="discount-info">Desconto: {{ formatMoney(details.discount) }}</h2>
    <h2 class="payment-amount">{{ formatMoney(details.amount - details.discount) }}</h2>
    <p class="payment-disclaimer">
      O crédito Neopag do cliente pode demorar até 30 minutos para ser atualizado.
    </p>
    <p class="payment-subtitle">Após o recebimento</p>
    <p class="payment-info">
      Crédito disponível para compra
      <br><strong>{{ $n(remainingCredit, 'currency') }}</strong>
    </p>
    <el-button
      class="submit-button"
      round
      type="primary"
      @click="handleReceiptConfirmation">
      Confirmar recebimento
    </el-button>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

import toMoney from "@/helpers/toMoney";

import PaymentSelectorList from '@/components/Payment/PaymentSelectorList';

export default {
  components: {
    PaymentSelectorList,
  },
  data() {
    return {
      showPredatedForm: false,
      predatedDate: undefined,
      isProcessingSale: false,
      loading: false,
    };
  },
  computed: {
    remainingCredit() {
      return (
        this.client.current_credit +
        this.details.amount
      );
    },
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      details: types.PAYMENT_DETAILS
    })
  },
  methods: {
    formatMoney(amount) {
      return toMoney(amount);
    },
    async handleReceiptConfirmation() {
      this.loading = true;
      this.isProcessingReceipt = true;
      try {
        await this.$store.dispatch(types.PAYMENT_SUBMIT, {
          payment: {
            amount: this.details.amount,
            payment_methods: [{
              amount: this.details.amount,
              payment_method: 'dinheiro',
            }],
            interest: this.details.interest,
            discount: this.details.discount,
          },
          store_customer_id: this.client.store_customer_id
        });
        await this.$store.dispatch(types.CLIENT, this.client.id).catch(() => {
          this.$notify.error({
            message: "Erro ao buscar as informações atualizadas do cliente",
            title: "Ops.."
          });
          this.loading = false;
        });
        this.$notify.success({
          message: "Recebimento realizada com successo!",
          title: "Sucesso!"
        });
        this.$router.push({ name: "Recibo do recebimento" });
      } catch(error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isProcessingSale = false;
      this.loading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.payment-summary {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 0 auto 1rem auto;

  .payment-amount {
    font-size: 48px;
    text-align: center;
    margin: 30px 0;
  }

  .discount-info {
    font-size: 18px;
    text-align: center;
    margin-top: 22px;
  }

  .discount-info + .discount-info {
    margin-top: 4px;
  }

  .payment-disclaimer,
  .payment-title,
  .payment-subtitle {
    color: $preto;
    text-align: center;
  }

  .payment-title {
    margin-bottom: 2rem;
    font-weight: 400;
    line-height: 1.45;
    strong {
      font-weight: 600;
    }
  }

  .payment-disclaimer {
    margin-bottom: 2rem;
    font-size: 0.75rem;
  }
  .payment-subtitle {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .submit-button {
    width: 100%;
    margin-top: 1.5rem;
  }
  .payment-info {
    padding: 0.5rem 0;
    margin: 0;
    font-family: $title-font;
    font-size: 1rem;
    font-weight: 400;
    color: $preto;
    text-align: center;
    border-top: 1px dashed rgba($preto, 0.25);
    strong {
      font-size: 1.3125rem;
      font-weight: 600;
    }
  }
  p + p {
    margin-top: 1rem;
  }
}
</style>
