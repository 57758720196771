<template>
  <escolher-cliente v-if="!isClientSet" title="Recebimento" show-consult-suggestion />
  <payment-wizard v-else-if="isClientSet && hasStatements" />
  <main v-else>
    <escolher-cliente
      :message="`${client.name} não possui faturas a receber.`"
      :client_id="client.id"
      :suggestion="suggestion"
      show-error
      title="Recebimento"
    />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import EscolherCliente from "@/containers/EscolherCliente";
import PaymentWizard from "@/containers/Payment/Wizard";

export default {
  components: {
    PaymentWizard,
    EscolherCliente
  },
  data() {
    return {
      suggestion: {
        label: "Vender",
        routeName: "Detalhes da venda"
      }
    };
  },
  computed: {
    ...mapGetters({
      isClientSet: types.CLIENT_IS_SET,
      client: types.CLIENT_DETAILS
    }),
    hasStatements() {
      const statements = this.client.user_statements;

      if (!statements) {
        return false;
      }

      const closed =
        (statements.closed_statement &&
          +statements.closed_statement.total_remaining_amount) ||
        0;
      const open =
        (statements.open_statement &&
          +statements.open_statement.total_remaining_amount) ||
        0;
      const future =
        (statements.future_statement &&
          +statements.future_statement.total_remaining_amount) ||
        0;

      return Math.abs(closed) + Math.abs(open) + Math.abs(future) > 0;
    }
  }
};
</script>
