<template>
  <section>
    <el-card v-loading="loading" class="payment-summary">
      <h2 class="payment-title">Confirmar recebimento de</h2>
      <h2 class="payment-amount">{{ formatMoney(details.amount) }}</h2>
      <h2 class="due_date-info">
        Data de vencimento:
        <b>{{ formatDate(details.due_date) }}</b>
      </h2>
      <p
        class="payment-disclaimer"
      >A liberação do crédito Neopag ocorrerá em até 2 dias úteis após o pagamento do boleto.</p>
      <p class="payment-subtitle">Após o recebimento</p>
      <p class="payment-info">
        Crédito disponível para compra
        <br />
        <strong>{{ $n(remainingCredit, 'currency') }}</strong>
      </p>
      <el-button
        class="submit-button"
        round
        type="primary"
        @click="handleReceiptConfirmation"
      >Gerar boleto</el-button>
    </el-card>
    <el-dialog class="bill-dialog" :visible.sync="showBilletDialog" close="handleNavigation">
      <h2>Compartilhe com o seu cliente</h2>
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      <br />
      <p>Esse é o link para o boleto, você pode compartilhar o link com o cliente.</p>
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      <br />
      <p>Clique em 'Boleto' para abrir o boleto</p>
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      <br />
      <a
        :href="succesBilletGeneratedResponse.data ? succesBilletGeneratedResponse.data.url : '' "
        class="payment-billet--billet-link"
        target="_blank"
      >Boleto</a>
      <!-- eslint-disable-next-line vue/no-parsing-error -->
      <br />
      <el-button
        class="payment-billet-btn"
        :disabled="!succesBilletGeneratedResponse"
        @click="copyToClipboard(succesBilletGeneratedResponse.data.url)"
      >Copiar link do Boleto</el-button>
    </el-dialog>
  </section>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";
import moment from "moment";

import toMoney from "@/helpers/toMoney";

export default {
  data() {
    return {
      showPredatedForm: false,
      predatedDate: undefined,
      isProcessingSale: false,
      showBilletDialog: false,
      succesBilletGeneratedResponse: {},
      loading: false
    };
  },
  computed: {
    remainingCredit() {
      return this.client.current_credit + this.details.amount;
    },
    ...mapGetters({
      client: types.CLIENT_DETAILS,
      details: types.PAYMENT_DETAILS
    })
  },
  methods: {
    formatMoney(amount) {
      return toMoney(amount);
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    async handleReceiptConfirmation() {
      this.loading = true;
      this.isProcessingReceipt = true;
      try {
        const params = {
          store_customer_id: this.client.store_customer_id,
          amount: this.details.amount,
          due_date: this.details.due_date
        };
        const response = await this.$store.dispatch(
          types.PAYMENT_SUBMIT_BILLET,
          params
        );
        this.$notify.success({
          message: "Boleto gerado com successo!",
          title: "Sucesso!"
        });
        this.succesBilletGeneratedResponse = response;
        this.showBilletDialog = true;
      } catch (error) {
        if (this.$raven && error.status === 500) {
          this.$raven.captureException(error.message, { req: error });
        }
        this.$notify.error({ title: error.message });
      }
      this.isProcessingSale = false;
      this.loading = false;
    },
    copyToClipboard(url) {
      navigator.permissions
        .query({ name: "clipboard-write" })
        .then(async result => {
          if (result.state == "granted" || result.state == "prompt") {
            navigator.clipboard
              .writeText(url)
              .then(() =>
                this.$notify({
                  title: "Copiado!",
                  message: "Código copiado com sucesso",
                  type: "success"
                })
              )
              .catch(() =>
                this.$notify.error({
                  title: "Erro!",
                  message: "Não foi possivel copiar o código de barras"
                })
              );
          }
        });
    }
  }
};
</script>

<style scoped lang="scss">
.payment-summary {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 0 auto 1rem auto;

  .payment-amount {
    font-size: 48px;
    text-align: center;
    margin: 30px 0;
  }

  .due_date-info {
    font-size: 18px;
    text-align: center;
    margin-top: 22px;
  }

  .due_date-info + .due_date-info {
    margin-top: 4px;
  }

  .payment-disclaimer,
  .payment-title,
  .payment-subtitle {
    color: $preto;
    text-align: center;
  }

  .payment-title {
    margin-bottom: 2rem;
    font-weight: 400;
    line-height: 1.45;
    strong {
      font-weight: 600;
    }
  }

  .payment-disclaimer {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 0.75rem;
  }
  .payment-subtitle {
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .submit-button {
    width: 100%;
    margin-top: 1.5rem;
  }
  .payment-info {
    padding: 0.5rem 0;
    margin: 0;
    font-family: $title-font;
    font-size: 1rem;
    font-weight: 400;
    color: $preto;
    text-align: center;
    border-top: 1px dashed rgba($preto, 0.25);
    strong {
      font-size: 1.3125rem;
      font-weight: 600;
    }
  }
  p + p {
    margin-top: 1rem;
  }
}
.payment-billet--billet-link {
  text-decoration: none;
  color: $laranja;
  font-size: 1.3rem;
  font-weight: bold;
}
.payment-billet-btn {
  margin-top: 1rem;
}
.bill-dialog {
  text-align: center;
}
</style>
