<template>
  <el-card class="payment-receipt">
    <i class="graphic el-icon-success" />
    <h2>Fatura paga</h2>
    <h4>
      Confirmamos o recebimento de
      <br />
      <strong>{{ $n(details.amount + details.interest - details.discount, 'currency') }}</strong> referente à sua fatura
    </h4>
    <div class="align-center">
      <el-button class="submit-button" type="primary" round @click="newPayment">Novo recebimento</el-button>
      <el-button round icon="el-icon-printer" :disabled="!response" @click="showReceipt()">Ver recibo</el-button>
    </div>
  </el-card>
</template>

<script>
import * as types from "@/store/types";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      canShare: false
    };
  },
  computed: {
    ...mapGetters({
      details: types.PAYMENT_DETAILS,
      response: types.PAYMENT_MORE_DETAILS,
    }),
    computedPayment() {
      if (this.details.payment.length) {
        return this.details.payment
          .map(it => it.amount)
          .reduce((a, b) => a + b);
      }
      return 0;
    }
  },
  mounted() {
    this.canShare = navigator.share;
  },
  methods: {
    async newPayment() {
      await this.$store.dispatch(types.CLIENT_RESET);
      await this.$store.dispatch(types.PAYMENT_RESET);
      this.$router.push({ name: "Detalhes do recebimento" });
    },
    showReceipt() {
      this.$router.push({
        name: "Imprimir recibo do recebimento"
      });
    }
  }
};
</script>

<style scoped lang="scss">
@import "./src/styles/theme.scss";

.payment-receipt {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  .graphic {
    margin-bottom: 1rem;
    font-size: 3rem;
    color: darken($verde, 10);
  }
  h2 {
    margin-bottom: 1rem;
  }
  h4 {
    font-weight: 400;
    line-height: 1.55;
  }
  .align-center {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-top: 1.5rem;
    .submit-button {
      flex: 0 auto;
      margin: auto;
    }
    .share-button {
      flex: 0 auto;
    }
  }
}
</style>
